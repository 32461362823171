<template>
  <div>
    <AppMenu />

    <!-- m="4" md="4" lg="4" -->
    <div tabindex="0">
      <v-row no-gutters>
        <v-col cols="12">
          <BaseBreadcrumb :title="$t(title)">
            <template v-slot:actions> </template>
          </BaseBreadcrumb>
          <v-card class="mt-3 mx-3">
            <div v-if="$store.getters.mobile">
              <v-list class="list-mobile">
                <v-divider></v-divider>
                <div v-for="item in items" :key="item.local_reference">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <div
                          class="d-flex justify-space-between list-mobile-field"
                        >
                          <span v-if="title" class="title-td"
                            >{{ $t("consecutive_order_text") }}:
                          </span>
                          <span>{{ item.consecutive_order_text }}</span>
                        </div>
                      </v-list-item-title>
                      <v-list-item-title>
                        <div
                          class="d-flex justify-space-between list-mobile-field"
                        >
                          <span v-if="title" class="title-td"
                            >{{ $t("reference") }}:
                          </span>
                          <span>{{ item.local_reference }}</span>
                        </div>
                      </v-list-item-title>
                      <v-list-item-title>
                        <div
                          class="d-flex justify-space-between list-mobile-field"
                        >
                          <span v-if="title" class="title-td"
                            >{{ $t("date") }}:
                          </span>
                          <span>{{ $filters.date(item.date_order) }}</span>
                        </div>
                      </v-list-item-title>
                      <v-list-item-title>
                        <div
                          class="d-flex justify-space-between list-mobile-field"
                        >
                          <span v-if="title" class="title-td"
                            >{{ $t("name_table") }}:
                          </span>
                          <span>{{ item.name_table }}</span>
                        </div>
                      </v-list-item-title>
                      <v-list-item-title>
                        <div
                          class="d-flex justify-space-between list-mobile-field"
                        >
                          <span v-if="title" class="title-td"
                            >{{ $t("price_tip") }}:
                          </span>
                          <span>{{
                            $filters.currency(item.price_tip, 0)
                          }}</span>
                        </div>
                      </v-list-item-title>
                      <v-list-item-title>
                        <div
                          class="d-flex justify-space-between list-mobile-field"
                        >
                          <span v-if="title" class="title-td"
                            >{{ $t("total") }}:
                          </span>
                          <span>{{
                            $filters.currency(item.total_to_pay, 0)
                          }}</span>
                        </div>
                      </v-list-item-title>
                      <v-list-item-title>
                        <div
                          class="d-flex justify-space-between list-mobile-field"
                        >
                          <span v-if="title" class="title-td"
                            >{{ $t("is_sync") }}:
                          </span>
                          <span>
                            <v-chip
                              small
                              dark
                              color="primary"
                              v-if="item.is_sync"
                            >
                              {{ $t("synchronized") }}
                            </v-chip>
                            <v-chip small dark color="red" v-else>
                              {{ $t("no_synchronized") }}
                            </v-chip>
                          </span>
                        </div>
                      </v-list-item-title>
                      <v-list-item-title>
                        <div
                          class="d-flex justify-space-between list-mobile-field"
                        >
                          <span v-if="title" class="title-td"
                            >{{ $t("order_status") }}:
                          </span>
                          <span v-if="item.order_status">
                            <v-chip
                              small
                              dark
                              :color="setStatusColor(item.order_status)"
                            >
                              {{ $t(item.order_status) }}
                            </v-chip>
                          </span>
                        </div>
                      </v-list-item-title>
                      <v-list-item-title>
                        <div
                          class="d-flex justify-space-between list-mobile-field"
                        >
                          <span v-if="title" class="title-td"></span>
                          <span>
                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>mdi-arrow-down</v-icon>
                                  {{ $t("actions") }}
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item class="d-flex flex-column mt-4">
                                  <v-btn
                                    block
                                    small
                                    class="mb-1"
                                    :title="$t('print_order')"
                                    elevation="1"
                                    @click="executePrintOrder(item)"
                                  >
                                    <v-icon>mdi-printer</v-icon>
                                    {{ $t("print_order") }}
                                  </v-btn>
                                  <v-btn
                                    block
                                    v-if="!item.is_sync"
                                    small
                                    class="mb-1"
                                    :title="$t('print_order')"
                                    elevation="1"
                                    @click="executeSyncOrder(item)"
                                  >
                                    <v-icon>mdi-cloud-upload-outline </v-icon>
                                  </v-btn>
                                  <v-btn
                                    v-if="showSupportOptions()"
                                    small
                                    tile
                                    plain
                                    class="mr-1"
                                    :title="$t('repair')"
                                    @click="executeSupport(item)"
                                  >
                                    <v-icon>mdi-auto-fix</v-icon>
                                    {{ $t("repair") }}
                                  </v-btn>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </span>
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider :key="item.id"></v-divider>
                </div>
              </v-list>
            </div>
            <div v-else class="px-2">
              <v-data-table
                mobile-breakpoint="300"
                :options.sync="options"
                :disable-filtering="true"
                v-on:update:page="changePage"
                v-on:update:sort-desc="changeOrder"
                v-on:update:items-per-page="changeItemsPerPage"
                :headers="headers"
                :items="items"
                :server-items-length="total"
                :loading="loading"
                class="listing-app"
                item-key="local_reference"
                :show-expand="false"
                :expanded.sync="expanded"
                :footer-props="{
                  'items-per-page-options': options.itemsPerPageOptions,
                  'items-per-page-text': $t('list.items_per_page'),
                }"
              >
                <!-- eslint-disable-next-line -->
                <template v-slot:header.id>
                  <span class="field-uuid">{{ $t("id") }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header.consecutive_order_text>
                  <span class="field-string">{{
                    $t("consecutive_order_text")
                  }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header.local_reference>
                  <span class="field-string">{{ $t("reference") }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header.bus_person_seller_id>
                  <span class="field-string">{{
                    $t("bus_person_seller_id")
                  }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header.bus_person_domiciliary_id>
                  <span class="field-string">{{
                    $t("bus_person_domiciliary_id")
                  }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header.date_order>
                  <span class="field-reference">{{ $t("date_order") }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header.name_table>
                  <span class="field-string">{{ $t("name_table") }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header.price_tip>
                  <span class="field-reference">{{ $t("price_tip") }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header.total_to_pay>
                  <span class="field-string">{{ $t("total") }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header.order_status>
                  <span class="field-string">{{ $t("order_status") }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header.is_sync>
                  <span class="field-string">{{ $t("is_sync") }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header._actions>
                  <span class="field-string">{{ $t("actions") }}</span>
                </template>

                <!-- eslint-disable-next-line -->
                <template v-slot:item.id="{ item }">
                  <span class="field-uuid">{{ item.id }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.local_reference="{ item }">
                  <span class="field-string">{{ item.local_reference }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.bus_person_seller_id="{ item }">
                  <span class="field-string" v-if="item.data_seller">{{
                    item.data_seller.name
                  }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.bus_person_domiciliary_id="{ item }">
                  <span class="field-string" v-if="item.data_domiciliary">{{
                    item.data_domiciliary.name
                  }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.date_order="{ item }">
                  <span class="field-date">{{
                    $filters.datetime(item.date_order)
                  }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.name_table="{ item }">
                  <span class="field-date">{{ item.name_table }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.price_tip="{ item }">
                  <span class="field-string">{{
                    $filters.currency(item.price_tip, 0)
                  }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.total_to_pay="{ item }">
                  <span class="field-string">{{
                    $filters.currency(item.total_to_pay, 0)
                  }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.order_status="{ item }">
                  <span class="field-string" v-if="item.order_status">
                    <v-chip
                      small
                      dark
                      :color="setStatusColor(item.order_status)"
                    >
                      {{ $t(item.order_status) }}
                    </v-chip>
                  </span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.is_sync="{ item }">
                  <span class="field-string">
                    <v-chip small dark color="primary" v-if="item.is_sync">
                      {{ $t("synchronized") }}
                    </v-chip>
                    <v-chip small dark color="red" v-else>
                      {{ $t("no_synchronized") }}
                    </v-chip>
                  </span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item._actions="{ item }">
                  <!--v-btn small tile plain class="mr-1" color="error" :title="$t('anulate')" @click="executeAnulate(item)">
                            <v-icon>mdi-cancel</v-icon>
                            {{$t('anulate')}}
                        </v-btn-->
                  <v-btn
                    small
                    tile
                    plain
                    class="mr-1"
                    :title="$t('print_order')"
                    @click="executePrintOrder(item)"
                  >
                    <v-icon>mdi-printer</v-icon>
                    {{ $t("order") }}
                  </v-btn>

                  <v-btn
                    v-if="!item.is_sync"
                    small
                    tile
                    plain
                    class="mr-1"
                    :title="$t('sync_order')"
                    @click="executeSyncOrder(item)"
                  >
                    <v-icon>mdi-cloud-upload-outline </v-icon>
                  </v-btn>

                  <v-btn
                    v-if="showSupportOptions()"
                    small
                    tile
                    plain
                    class="mr-1"
                    :title="$t('repair')"
                    @click="executeSupport(item)"
                  >
                    <v-icon>mdi-auto-fix</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </div>
            <div
              v-if="$store.getters.mobile"
              class="d-flex justify-center pt-1 pb-2"
            >
              <v-btn
                small
                color="primary"
                @click="loadMore()"
                :loading="loadingMore"
                >{{ $t("view_more_with_points") }}</v-btn
              >
            </div>
          </v-card>
        </v-col>
      </v-row>
      <LocalOrderDetail
        :dialog="modalLocalOrderDetail"
        :currentOrder="currentOrder"
        @cancel="cancel"
      ></LocalOrderDetail>
    </div>
  </div>
</template>
<script>
import AppMenu from "../menu/AppMenu.vue";

import MixinActions from "@/components/mixins/MixinListActions";
import MixinModalDynamic from "@/components/mixins/MixinModalDynamic";
import LocalOrderDetail from "./LocalOrderDetail";

export default {
  name: "LocalOrders",
  mixins: [MixinActions, MixinModalDynamic],
  components: { LocalOrderDetail, AppMenu },
  data: () => ({
    app_version: null,
    showFilters: false,
    items: [],
    total: 20,
    itemsKey: 1,
    loading: true,
    loadingMore: false,
    modalLocalOrderDetail: false,
    currentOrder: null,
    expanded: [],
    title: "Ordenes",
    options: {
      page: 1,
      itemsPerPage: 10,
      itemsPerPageOptions: [10, 25, 50, 100],
    },
    headers: [
      {
        title: "consecutive_order_text",
        value: "consecutive_order_text",
        sortable: false,
      },
      { title: "local_reference", value: "local_reference", sortable: false },
      {
        title: "bus_person_seller_id",
        value: "bus_person_seller_id",
        sortable: false,
      },
      {
        title: "bus_person_domiciliary_id",
        value: "bus_person_domiciliary_id",
        sortable: false,
      },
      { title: "date_order", value: "date_order", sortable: false },
      { title: "name_table", value: "name_table", sortable: false },
      { title: "price_tip", value: "price_tip", sortable: false },
      { title: "total_to_pay", value: "total_to_pay", sortable: false },
      { title: "is_sync", value: "is_sync", sortable: false },
      { title: "order_status", value: "order_status", sortable: false },
      { title: "_actions", value: "_actions", sortable: false },
    ],
    //cart: null
    //priceList:
  }),
  computed: {
    pages() {
      return Math.ceil(this.total / this.options.itemsPerPage);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    openHelp() {
      window.open(process.env.VUE_APP_BASECONFIG_URL_HELP, "_blank");
    },
    launchMenuPos(evt) {
      return false;
    },
    async load() {
      setTimeout(async () => {
        this.app_version = process.env.VUE_APP_BASECONFIG_APP_VERSION;
        await this.$db.load();
        const installed = await this.$db.itsInstalled();

        this.$db
          .getTotalLocalInvoices()
          .then((orders) => {
            this.total = orders;
            this.searchOrders();
          })
          .catch((err) => {
            console(err);
          });
      }, 500);
    },
    toogleDrawer() {
      this.$store.commit("toggle");
    },
    setStatusColor(status) {
      switch (status) {
        case "created":
          return "red";
          break;
        case "pending":
          return "warning";
          break;
        default:
          return "error";
          break;
      }
    },
    async executePrintOrder(item) {
      this.$pos.printOrderServer(item);
    },
    async executeSyncOrder(item) {
      /* const installation = this.$store.getters.installation;
       const user = this.$store.getters.user;
       // const turn = this.$store.getters.turn;
       if(!item.bus_subsidiary_id){
         item.bus_subsidiary_id = installation.subsidiary_id;
       }
       
       if(!item.bus_person_cashier_id){
         item.bus_person_cashier_id = user.id;	
       }
       if(!item.data_cashier){
         item.data_cashier = user;		
       }
       if(!item.bill_cash_register_id){
         item.bill_cash_register_id = installation.cash_id;	
       }  */

      this.$pos.saveOrder(item);
      this.load();
    },
    executeEdit(item) {},
    async searchOrders(loadMore = false) {
      const local_orders = await this.$db.searchLocalOrders(
        {},
        this.options.itemsPerPage,
        this.options.page,
        "date_order"
      );
      if (loadMore) {
        this.items = [...this.items, ...local_orders];
        this.total = this.items.length;
        this.loadingMore = false;
      } else {
        this.items = local_orders;
      }

      this.loading = false;
      this.itemsKey++;
    },
    async changePage(e) {
      this.searchOrders();
    },
    changeItemsPerPage(e) {
      this.searchOrders();
    },
    changeOrder(e) {
      this.searchOrders();
    },
    validateSync(item) {
      if (item.is_sync && item.data_payments) {
        return true;
      }
      return false;
    },
    executeSupport(item) {
      this.modalLocalOrderDetail = true;
      this.currentOrder = item;
    },
    loadMore() {
      this.options.page++;
      this.loadingMore = true;
      setTimeout(async () => {
        this.searchOrders(true);
      }, 500);
    },
    cancel() {
      this.modalLocalOrderDetail = false;
      this.currentOrder = null;
      this.load();
    },
    showSupportOptions() {
      if (
        this.$store.getters.getGeneralValue("gen_pos_activate_support_options")
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
