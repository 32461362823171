<template>
  <div>
    <v-dialog v-model="dialog" persistent fullscreen>
      <v-container fill-height fluid class="bg_modal">
        <v-row v-if="currentOrder">
          <v-col sm="8" md="6" lg="4" offset-sm="2" offset-md="3" offset-lg="4">
            <ValidationObserver v-slot="{ invalid }" ref="form">
              <v-card>
                <v-app-bar flat color="primary">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="white"
                    icon
                    @click="$emit('cancel')"
                    :title="$t('Cancel')"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-app-bar>
                <v-card-text>

                <v-row class="pa-1 pb-0">

                  <v-col cols="12" class="py-0">
                      <v-row class="">
                        <v-col md="5">
                          <span class="title font-weight-medium"
                            >{{ $t("date_order") }}:
                          </span>
                        </v-col>
                        <v-col >
                          <span class="title font-weight-regular">
                            {{ $filters.date(currentOrder.date_order)  }}
                          </span>
                        </v-col>
                      </v-row>
                  </v-col>

                  <v-col cols="12" class="py-0">
                      <v-row class="">
                        <v-col md="5">
                          <span class="title font-weight-medium"
                            >{{ $t("consecutive_order") }}:
                          </span>
                        </v-col>
                        <v-col >
                          <span class="title font-weight-regular">
                            {{ currentOrder.consecutive_order  }}
                          </span>
                        </v-col>
                      </v-row>
                  </v-col>
                  <v-col cols="12" class="py-0">
                      <v-row class="">
                        <v-col md="5">
                          <span class="title font-weight-medium"
                            >{{ $t("consecutive_order_text") }}:
                          </span>
                        </v-col>
                        <v-col >
                          <span class="title font-weight-regular">
                            {{ currentOrder.consecutive_order_text  }}
                          </span>
                        </v-col>
                      </v-row>
                  </v-col>

                  <v-col cols="12" class="py-0">
                      <v-row class="">
                        <v-col md="5">
                          <span class="title font-weight-medium"
                            >{{ $t("bus_person_customer_id") }}:
                          </span>
                        </v-col>
                        <v-col >
                          <span class="title font-weight-regular" v-if="currentOrder.data_customer && currentOrder.data_customer.name">
                            {{ currentOrder.data_customer.name  }}
                          </span>
                        </v-col>
                      </v-row>
                  </v-col>

                  <v-col cols="12" class="py-0">
                      <v-row class="">
                        <v-col md="5">
                          <span class="title font-weight-medium"
                            >{{ $t("bus_person_domiciliary_id") }}:
                          </span>
                        </v-col>
                        <v-col >
                          <span class="title font-weight-regular" v-if="currentOrder.data_domiciliary && currentOrder.data_domiciliary.name">
                            {{ currentOrder.data_domiciliary.name  }}
                          </span>
                        </v-col>
                      </v-row>
                  </v-col>
                  
                  <v-col cols="12" class="py-0">
                      <v-row class="">
                        <v-col md="5">
                          <span class="title font-weight-medium"
                            >{{ $t("total_to_pay") }}:
                          </span>
                        </v-col>
                        <v-col >
                          <span class="title font-weight-regular">
                            {{$filters.currency(currentOrder.total_to_pay, 0)}}
                          </span>
                        </v-col>
                      </v-row>
                  </v-col>
                <v-col cols="12" class="py-0">
                      <v-row class="">
                        <v-col md="5">
                          <span class="title font-weight-medium"
                            >{{ $t("price_delivery") }}:
                          </span>
                        </v-col>
                        <v-col >
                          <span class="title font-weight-regular">
                            {{$filters.currency(currentOrder.price_domicile, 0)}}
                          </span>
                        </v-col>
                      </v-row>
                  </v-col>
                <v-col cols="12" class="py-0" >
                      <v-row class="">
                        <v-col md="5">
                          <span class="title font-weight-medium"
                            >{{ $t("price_tip") }}:
                          </span>
                        </v-col>
                        <v-col >
                          <span class="title font-weight-regular">
                            {{$filters.currency(currentOrder.price_tip, 0)}}
                          </span>
                        </v-col>
                      </v-row>
                  </v-col>


                  <v-col cols="12" class="pt-3" >
                      <v-row class="">
                        <v-col cols="12">
                          <span class="title font-weight-medium"
                            >{{ $t("products") }}:
                          </span>
                        </v-col>
                        <v-col cols="12" class="py-0" v-for="item in currentOrder.data_items" :key="item.local_reference">
                          <span class="title font-weight-medium">
                            {{item.product.name}}
                          </span><br/>
                          <span class="title font-weight-regular">
                            {{item.quantity}}x{{$filters.currency(item.unit_price, 0)}}
                          </span>
                        </v-col>
                      </v-row>
                  </v-col>
                </v-row>

                

                <v-row class="pa-4">

                  <v-col cols="12" class="py-0">
                    <ng-text-keyboard
                      v-model="currentOrder.bill_cash_register_turn_id"
                      :label="$t('bill_cash_register_turn_id')"
                      :filled="false"
                      dense
                      show-required="true"
                      rows="3"
                      rules="required"
                    ></ng-text-keyboard>
                  </v-col> 

                  <v-col cols="12" class="py-0">
                    <ng-text-keyboard
                      v-model="currentOrder.order_status"
                      :label="$t('order_status')"
                      :filled="false"
                      dense
                      show-required="true"
                      rows="3"
                      rules="required"
                    ></ng-text-keyboard>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <ng-text-keyboard
                      v-model="currentOrder.local_reference"
                      :label="$t('local_reference')"
                      :filled="false"
                      dense
                      show-required="true"
                      rows="3"
                      rules="required"
                    ></ng-text-keyboard>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <ng-text-keyboard
                      v-model="currentOrder.turn_local_reference"
                      :label="$t('turn_local_reference')"
                      :filled="false"
                      dense
                      show-required="true"
                      rows="3"
                      rules="required"
                    ></ng-text-keyboard>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <ng-date
                      v-model="currentOrder.date_order"
                      :label="$t('date_order')"
                      :filled="false"
                      dense
                      show-required="true"
                      rows="3"
                      rules="required"
                    ></ng-date>
                  </v-col>

                </v-row>
              </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    outlined
                    color="primary"
                    @click="$emit('cancel')"
                    :title="$t('Cancel')"
                  >
                    {{ $t("Cancel") }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="update"
                    :disabled="invalid"
                    :title="$t('update')"
                  >
                    {{ $t("update") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </ValidationObserver>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "LocalOrderDetail",
  props: ["currentOrder", "dialog"],
  data: () => ({
    note:null
  }),
  async mounted() {
  },

  methods: {
    async update() {
      
      let send_order = this.currentOrder;
      // fix subsidiary      
      if(send_order.bus_subsidiary_id){
        let subsidiary = send_order.bus_subsidiary_id;
        if(send_order.bus_subsidiary_id.id){
          subsidiary = send_order.bus_subsidiary_id.id;
          if(send_order.bus_subsidiary_id.id.id){
            subsidiary = send_order.bus_subsidiary_id.id.id;
          }
        }
        send_order.bus_subsidiary_id = subsidiary;
        send_order.subsidiary = subsidiary;
      }
      this.$pos.saveOrder(send_order);
    },
    
  },
};
</script>